import '../css/modal.css';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const VerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
        <Modal.Footer>
          <Button className="cancel-btn btn btn-outline-secondary btn-lg closeButton" onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};