import './App.css';
import React, { Component } from 'react';
import { AddUserForm } from './components/form';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './aws-exports';
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { UserBox } from './components/userBox';
import { Header } from './components/header';

const {APP_DOMAIN} = process.env;

Amplify.configure(awsConfig);

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: null,
      data: null,
      isPremisionAdd: false,
      isSubmitting: false,
      isEditing: false,
      isPremmisionUpdate: false
    };
  }

  authUser = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
          this.setState({user: user.attributes.email});
          this.loadData();
        }
      )
      .catch(err => console.log('Auth Error', err));
  };

  onSubmit = async (values, {setSubmitting, resetForm}) => {
    const requestOptions = {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(values)};
    this.setState({isSubmitting: true});
    fetch('/create', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.hasOwnProperty('errorCode')) {
          return this.setState({error: data.errorCode, isSubmitting: false});
        }
        resetForm();
        setSubmitting(false);
        this.loadData();
        this.setState({isSubmitting: false});
      })
      .catch(err => console.error(err.toString()));
  };

  updateUser = async (values, {setSubmitting}) => {
    const editUserParams = {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(values)};
    this.setState({isEditing: true});
    fetch('/update', editUserParams)
      .then(response => response.json())
      .then(data => {
        setSubmitting(false);
        this.loadData();
        this.setState({isEditing: false});
      })
      .catch(err => console.error(err.toString()));
  };

  addPremission = async (values, {setSubmitting}) => {
    this.setState({isPremisionAdd: true});
    const addPremissionParams = {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(values)};
    fetch('/addPremission', addPremissionParams)
      .then(response => response.json())
      .then(data => {
        setSubmitting(false);
        this.loadData();
        this.setState({isPremisionAdd: false});
      })
      .catch(err => console.error(err.toString()));
  };

  updatePremission = async (values, {setSubmitting}) => {
    const editPremissionParams = {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(values)};
    this.setState({isPremmisionUpdate: true});
    fetch('/updatePremission', editPremissionParams)
      .then(response => response.json())
      .then(data => {
        setSubmitting(false);
        this.loadData();
        this.setState({isPremmisionUpdate: false});
        this.setState({modalState: 0});

      })
      .catch(err => console.error(err.toString()));
  };

  loadData = () => {
    const requestOptions = {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({user: this.state.user})};
    fetch('/getdata', requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({data: data});
      })
      .catch(err => console.error(err.toString()));
  };

  componentDidMount () {
    this.authUser();
  }

  render () {
    const {modalState, data, isSubmitting, isDeleting, isEditing, user, isPremmisionUpdate, isPremisionAdd} = this.state;

    if (data === null) {
      return <div className="loader d-flex flex-column align-items-center justify-content-center">
        <div className="row">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className="row">
          <strong>Collecting data</strong>
        </div>
      </div>;
    }

    if (!data.Admin) {
      return <div className="loader">
        <h2>you have no permission to perform this action</h2>
        <h4>contact your administrator</h4>
      </div>;
    }

    //Remove duplicate accounts
    const dataFilter = data.Accounts.filter((v, i, a) => a.findIndex(t => (t.Id === v.Id)) === i);
    //Remove duplicate areas
    const dataAreas = data.AccessAreas.filter((v, i, a) => a.findIndex(t => (t.Id === v.Id)) === i);
    //Filter all email accounts registered for the duplicate email validation
    const dataEmails = data.AllAccounts.records.map(a => a.Email_Adress__c);

    const mergeById = (a1, a2) => {
      // data.Permissions was missing
      return (a1 || []).map(itm => ({
        ...a2.find((item) => (item.Id === itm.BI_Access_Area__c) && item),
        ...itm
      }));
    };

    return (
      <div>
        <Header/>
        <div className="w-100">
          <div className="MainContent row">
            <div className="Sidebar col-md-4">
              <div className="AddUserColumn">
                <h4 className="ColumnTitle">Add New User</h4>
                <AddUserForm userLogged={user} emails={dataEmails} isSubmitting={isSubmitting} submit={this.onSubmit} areas={dataAreas}/>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row p-0 m-0">
                <h4 className="ColumnTitleMain w-100">Current Users</h4>
                {dataFilter.map((d, index) => (
                  <React.Fragment key={index}>
                    <UserBox
                      p={mergeById(data.Premissions, dataAreas).filter(e => e.BI_User_Profile__c === d.Id)}
                      id={d.Id}
                      name={d.First_Name__c}
                      lastName={d.Last_Name__c}
                      email={d.Email_Adress__c}
                      admin={d.External_Admin__c}
                      validFrom={d.Valid_From__c}
                      validTo={d.Valid_To__c}
                      edit={this.updateUser}
                      addPremission={this.addPremission}
                      premissionEdit={this.updatePremission}
                      isPremissionAdd={isPremisionAdd}
                      isDeleting={isDeleting}
                      isEditing={isEditing}
                      isPremmisionUpdate={isPremmisionUpdate}
                      areas={dataAreas}
                      owner={d.Email_Adress__c === user ? true : false}
                      modalState={modalState}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuthenticator(App);

