import '../css/userBox.css';
import React, { useState, useEffect } from 'react';
import { VerticallyCenteredModal } from './modal';
import { ModalPrompt } from './modalPrompt';
import Button from 'react-bootstrap/Button';
import { EditUserForm } from './editUser';
import { EditPremissionForm } from './editPremission';
import { AddPremissionForm } from './addPermission';
import { MdModeEdit, MdAdd } from 'react-icons/md';
import dayjs from 'dayjs';
import Spinner from 'react-bootstrap/Spinner';

export const UserBox = (props) => {

  const today = dayjs().format('YYYY-MM-DD');

  const [modalShow, setModalShow] = useState(false);
  const [editPremissionShow, seteditPremissionShow] = useState(null);
  const [addPremmision, setAddPremission] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  useEffect(() => {
    if (props.isPremmisionUpdate === false) {
      seteditPremissionShow(null);
    }
    if (props.isEditing === false) {
      setModalShow(false);
    }
    if (props.isPremissionAdd === false) {
      setAddPremission(false);
    }
  }, [props.isPremmisionUpdate, props.isEditing, props.isPremissionAdd]);

  return (
    <div className={`col userBox p-3 mr-3 mb-3 ${props.owner === true ? 'firstUser' : ''}`}>
      <p className="card-title userName">{props.name} {props.lastName} </p>
      <p className="userEmail">{props.email}</p>
      {props.admin === true && <p className="userAdmin">Admin</p>}
      <p className="userValid">Valid: {props.validFrom} - {props.validTo}</p>
      <p>Permissions</p>
      {props.p.map((i, index) => {
        const status = i.Valid_From__c > today || i.Valid_To__c < today ? 'inactive' : 'active';
        return <React.Fragment key={index}>
          <VerticallyCenteredModal title={props.owner === false ? 'Edit Permission' : 'Permission Info'} show={editPremissionShow === index} onHide={() => seteditPremissionShow(null)}>
            <div className="premissionInfo bg-light bg-gradient p-3 mb-3">
              <span className="clearfix">User: {props.email}</span>
              <span className="clearfix">Acess Area: {i.Name}</span>
              <span className="clearfix">Valid: {i.Valid_From__c} - {i.Valid_To__c}</span>
              <span className="clearfix">Status: {status}</span>
            </div>
            {props.owner === false &&
            <EditPremissionForm
              initValue={{
                ValidTo: i.Valid_To__c,
                ValidFrom: i.Valid_From__c,
                Id: i.Id
              }}
              submit={props.premissionEdit}
              isPremmisionUpdate={props.isPremmisionUpdate}
            />
            }
          </VerticallyCenteredModal>
          <Button onClick={(e) => seteditPremissionShow(index)} className={`${status} editPerm btn btn-outline-success mr-2 mb-1 shadow-none`} variant="secondary">{i.Name}</Button>
        </React.Fragment>;
      })}


      <VerticallyCenteredModal title={'Edit User'} show={modalShow} onHide={() => setModalShow(false)}>
        <EditUserForm initValue={{
          Name: props.name,
          LastName: props.lastName,
          Email: props.email,
          Admin: props.admin,
          ValidTo: props.validTo,
          ValidFrom: props.validFrom,
          UserId: props.id
        }}
                      areas={props.areas}
                      submit={props.edit}
                      isEditing={props.isEditing}

        />
      </VerticallyCenteredModal>


      <VerticallyCenteredModal title={`Add Permission`} show={addPremmision} onHide={() => setAddPremission(false)}>
        <AddPremissionForm isAdding={props.isPremissionAdd} submit={props.addPremission} UserId={props.id} areas={props.areas} areasAttached={props.p}/>
      </VerticallyCenteredModal>

      {props.owner === false &&
      <div className="mt-4 border-top pt-3">
        <button className="btn btn-outline-info editUserBtn editUser" onClick={() => setModalShow(true)}>
          <MdModeEdit className="addIcon mr-2"/> Edit User
        </button>
        <button className="btn btn-outline-success float-right shadow-none addPermission-btn" onClick={() => setAddPremission(true)}>
          <MdAdd className="addIcon mr-2"/>Add Permission
        </button>
      </div>
      }
    </div>

  );
};



